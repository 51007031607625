import React from "react";
import { graphql } from "gatsby";
import GroupedPostTemplate from "../../components/layout/grouped-post-template";

const Briefing = ({ data: { post } }) => {
  return <GroupedPostTemplate post={post} />;
};

export const query = graphql`
  query BriefingQuery($slug: String!) {
    post: wpBriefing(slug: { eq: $slug }) {
      slug
      title
      date: dateGmt(formatString: "DD/MM/YY")
      additionalInfo {
        standfirst
        author
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      themeCategories {
        nodes {
          name
          slug
        }
      }
      blocks {
        name
        ... on WpCoreQuoteBlock {
          attributes {
            ... on WpCoreQuoteBlockAttributes {
              value
              citation
            }
          }
        }
        ... on WpCoreParagraphBlock {
          attributes {
            ... on WpCoreParagraphBlockAttributes {
              content
            }
          }
        }
        ... on WpCoreEmbedBlock {
          attributes {
            ... on WpCoreEmbedBlockAttributes {
              caption
              url
            }
          }
        }
        ... on WpCoreListBlock {
          attributes {
            values
            ordered
          }
        }
        ... on WpCoreHeadingBlock {
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              content
              level
            }
          }
        }
        ... on WpCoreGroupBlock {
          innerBlocks {
            ... on WpCoreQuoteBlock {
              name
              attributes {
                ... on WpCoreQuoteBlockAttributes {
                  value
                  citation
                }
              }
            }
            ... on WpCoreEmbedBlock {
              name
              attributes {
                ... on WpCoreEmbedBlockAttributes {
                  caption
                  url
                }
              }
            }
            ... on WpCoreTableBlock {
              saveContent
              name
            }
            ... on WpCoreImageBlock {
              name
              attributes {
                ... on WpCoreImageBlockAttributes {
                  url
                  caption
                }
              }
            }
          }
          name
        }
        ... on WpCoreTableBlock {
          saveContent
          name
        }
        ... on WpCoreImageBlock {
          attributes {
            ... on WpCoreImageBlockAttributes {
              url
              caption
            }
          }
          name
        }
      }
    }
  }
`;

export default Briefing;
