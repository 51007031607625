import React, { useState } from "react";
import { Grid, Flex, Box, Text } from "@theme-ui/components";
import AppButton from "./layout/app-button";
import genId from "../lib/gen-id";

const Bullet = () => (
  <Box
    className="menu-bullet"
    sx={{
      width: 9,
      height: 9,
      border: "1px solid white",
      borderRadius: "100%",
      marginLeft: "8px",
      marginTop: "3.5px",
    }}
  />
);

const MenuItem = ({ heading, onClick }) => (
  <Grid
    onClick={onClick}
    columns={16}
    as="a"
    href={`#${genId(2, heading)}`}
    sx={{
      mb: 2,
    }}
  >
    <Flex
      sx={{
        gridColumn: "span 2",
        alignItems: "flex-start",
      }}
    >
      <Bullet />
    </Flex>
    <Text
      sx={{
        color: "white",
        fontWeight: 100,
        gridColumn: "span 14",
        fontSize: [2],
      }}
      dangerouslySetInnerHTML={{ __html: heading }}
    />
  </Grid>
);

const GroupedPostNav = ({ headings }) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((prev) => !prev);
  };
  return (
    <Flex
      sx={{
        flexDirection: "column",
        position: "fixed",
        bottom: [3],
        right: [3],
        zIndex: 100,
        alignItems: "flex-end",
      }}
    >
      <Box
        sx={{
          height: show ? "initial" : 0,
          opacity: show ? 1 : 0,
          transition: "opacity 0.1s ease-out",
          bg: "#47464EEA",
          color: "white",
          borderRadius: [15, 20],
          px: [3],
          py: [4],
          width: 260,
          maxHeight: "60vh",
          overflowY: "scroll",
          mb: [3],
        }}
      >
        {headings.map((heading, index) => (
          <MenuItem
            onClick={() => setTimeout(() => toggleShow(), 50)}
            key={index}
            heading={heading}
          />
        ))}
        <Box sx={{ mt: [4, 5] }}>
          <MenuItem
            onClick={() => setTimeout(() => toggleShow(), 50)}
            heading={"Download PDF"}
          />
        </Box>
        <Box sx={{ mt: [4] }}>
          <MenuItem
            onClick={() => setTimeout(() => toggleShow(), 50)}
            heading={"Back to top"}
          />
        </Box>
      </Box>
      <AppButton
        onClick={toggleShow}
        icon={show ? "arrowDown" : "index"}
        sx={{
          bg: "#47464EEA",
          p: 0,
          px: [3],
          width: "fit-content",
          color: "white",
          zIndex: 100,
        }}
      >
        Index
      </AppButton>
    </Flex>
  );
};

export default GroupedPostNav;
