import React from "react";
import { Box, Grid } from "theme-ui";
import Layout from "../layout";
import ColumnContainer from "./column-container";
import ThemeableHeader from "./themeable-header";
import PdfDownload from "../page-blocks/pdf-download";
import Seo from "../seo";
import ArticleMeta from "../article-meta";
import componentMapper from "../content-blocks/component-mapper";
import createRenderGroups from "../content-blocks/create-render-groups";
import GroupedPostNav from "../../components/grouped-post-nav";
import genId from "../../lib/gen-id";
import NewsletterSignup from "../page-blocks/newsletter-signup";
import TwitterCallToAction from "../page-blocks/twitter-cta";
import ReusableLatestContent from "../page-blocks/reusable-latest-content";

const GroupedPostTemplate = ({ post }) => {
  const renderGroups = createRenderGroups(post.blocks);
  const headings = renderGroups.map((group) => group[0].attributes.content);
  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.additionalInfo.standfirst}
        image={post?.featuredImage?.node.mediaItemUrl}
      />
      <ThemeableHeader
        color="coral"
        title={post.title}
        subtitle={post?.additionalInfo?.standfirst}
        active="/"
        theme={post?.themeCategories?.nodes[0]?.name}
        backgroundImage={post?.featuredImage?.node.mediaItemUrl || false}
      />
      <GroupedPostNav headings={headings} />
      <Grid as="section" columns={1} gap={[4, 5]} sx={{ mb: [5, 6] }}>
        <ColumnContainer>
          <ArticleMeta
            date={post?.date}
            author={post?.additionalInfo?.author}
            articleTitle={post.title}
          />
        </ColumnContainer>
        {renderGroups.map((group, index) => (
          <Box
            id={`${genId(index, group[0].attributes.content)}`}
            key={`group-${index}`}
            sx={{
              breakInside: "avoid",
              pageBreakInside: "avoid",
            }}
          >
            <ColumnContainer>
              <Box
                className="section-body"
                as="section"
                sx={{
                  bg: "offWhite",
                  p: [3, 4, 5],
                  py: [4, 4, 5],
                  borderRadius: 30,
                }}
              >
                {componentMapper(group)}
              </Box>
            </ColumnContainer>
          </Box>
        ))}
        <Box className="hide-on-pdf">
          <ReusableLatestContent title="Read More" />
        </Box>
        <Box className="hide-on-pdf">
          <TwitterCallToAction />
        </Box>
        <Box className="hide-on-pdf">
          <PdfDownload articleTitle={post.title} />
        </Box>
        <Box className="hide-on-pdf">
          <NewsletterSignup />
        </Box>
      </Grid>
    </Layout>
  );
};

export default GroupedPostTemplate;
