const createRenderGroups = (blocks) => {
  const h2Headings = blocks
    .map((block, index) => {
      return {
        ...block,
        originalIndex: index,
      };
    })
    .filter((block) => {
      return block.name === "core/heading" && block.attributes.level === 2;
    });
  const renderGroups = [];
  h2Headings.forEach((heading, index) => {
    const firstIndex = heading.originalIndex;
    let lastIndex;
    if (!(index + 1 === h2Headings.length)) {
      lastIndex = h2Headings[index + 1].originalIndex - 1;
    } else {
      lastIndex = blocks.length - 1;
    }
    renderGroups.push(blocks.slice(firstIndex, lastIndex + 1));
  });
  return renderGroups;
};

export default createRenderGroups;
